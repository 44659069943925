<template>
  <b-modal
    class="backupRestoreModal"
    hide-header
    hide-footer
    centered
    ref="backupRestoreModal"
    @open="open"
  >
    <b-form @submit="onSubmit" @reset="onReset" class="p-4">
      <b-row>
        <b-col>
          <p>Select the store you want to perform "Restore" to</p>
        </b-col>
      </b-row>

      <b-form-row>
        <b-col>
          <b-form-group label-size="lg" class="mb-0">
            <b-form-radio-group
              v-model="storeSelected"
              :options="availableStores"
              stacked
              name="elm_store"
              class="store-list"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-row class="form-actions">
        <b-col>
          <p-button type="success" round size="sm" class="pull-right" :disabled="hasError() || processing" @click.native="onSubmitForm" :loading="processing">Submit</p-button>
          <p-button type="danger" round size="sm" class="pull-right mr-2" @click.native="onCancel">Cancel</p-button>
        </b-col>
      </b-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      processing: false,
      initial: {
      },
      form: {
      },
      storeSelected: '',
    }
  },
  computed: {
    ...mapGetters('shopify/stores', [
      'stores',
      'store',
      'loading',
    ]),
    availableStores: function() {
      return this.stores.map(store => store.name)
    }
  },
  methods: {
    open(params) {
      this.initial = params
      this.resetFormFields()
      this.$refs.backupRestoreModal.show()
    },
    close() {
      this.$refs.backupRestoreModal.hide()
    },
    onSubmit() {
      this.onSubmitForm()
    },
    hasError() {
      return !this.storeSelected || !this.initial.backup
    },
    onCancel() {
      this.close()
    },
    onReset() {
      this.resetFormFields()
    },
    resetFormFields() {
      // this.storeSelected = ''
      this.storeSelected = this.initial.store && this.initial.store.name || ''
      this.processing = false
    },
    onSubmitForm(e) {
      if (!confirm(`Do you really want to restore to <${this.storeSelected}>?`)) return;
      e && e.preventDefault()
      this.processing = true

      this.$store.dispatch('shopify/stores/backupRestore', {
        id: this.initial.backup.id,
        store: this.storeSelected,
      }).then(() => {
        this.processing = false
        this.close()
      })

      // const {error, ...payload} = this.form
      // this.$store.dispatch('shopify/stores/remove', payload)
      // .then(() => this.$router.push('/shopify'))
      // .finally(() => {
      //   this.processing = false
      //   this.close()
      // })
    }
  },
  created() {
    this.resetFormFields()
  },
}
</script>
