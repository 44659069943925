<template>
  <div class="wrapper">
    <div class="auth-panel">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
