export default {
  add(state, {logs}) {
    state.logs.push(...logs)
  },
  load(state, {logs}) {
    state.logs = logs
    state.loading = false
  },
  loading(state, loading) {
    state.loading = loading
  },
}
