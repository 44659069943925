<template>
  <div>
    <b-row>
      <b-col>
        <dashboard-intro />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DashboardIntro from './Dashboard/Intro'
export default {
  components: {
    DashboardIntro,
  },
};
</script>
<style>
</style>
