<template>
  <div class="panel login-panel">
    <div class="header-content">
    </div>
    <div class="content-container">
      <card class="login-panel-content">
        <p class="text-center"><router-link to="/"><img src="@/assets/img/favicon.png" alt="" /></router-link></p>
        <form @submit.prevent>
          <b-button type="button" variant="outline-primary" block @click="loginWithMS" :disabled="authLoading || msLoginUrlLoading">
            <template v-if="msLoginUrlLoading">
              <i class="fa fa-spinner fa-spin mr-1" />
            </template>
            <template v-else>
              <i class="fa fa-windows mr-3" />
              Continue with Microsoft
            </template>
          </b-button>
          <div class="text-center mt-4">
            <small class="text-muted">All Access is Logged - Unauthorised access is subject to criminal prosecution.</small>
          </div>
        </form>
      </card>
    </div>
    <div class="footer-content">
    </div>
    <div class="footer-content" style="margin-top: 2rem;">
    </div>

    <div class="copyright">&copy; {{year}} <strong>Ecofibre</strong> ALL RIGHTS RESERVED</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      msLoginUrlLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      'authError': 'error',
      'authLoading': 'loading',
    }),
  },
  methods: {
    loginWithMS() {
      this.msLoginUrlLoading = true
      this.$http.post('auth/request-ms-login')
      .then(resp => {
        const {
          login_url,
        } = resp.data
        location.href = login_url
      })
    },
  },
  mounted() {
    if (this.$route.query.type == 'error' && this.$route.query.code) {
      if (this.$route.query.message) {
        this.$notify({
          message: this.$route.query.message,
          type: 'danger',
        })
        this.$router.push('/login')
      }
    }
  },
}
</script>
<style lang="scss">
.login-panel {
  min-width: 360px;
  .header-content {
    text-align: center;
    margin-bottom: 1rem;
  }
  .login-panel-content {
    padding: 20px 20px 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    form {
      max-width: 300px;
      margin: 30px auto;
    }
    .btn {
      text-transform: initial;
      height: 40px;
      box-shadow: 0 0 4px 2px #dedede;
      // display: flex;
      // justify-content: space-evenly;
      img {
        margin-right: 25px;
      }
    }
  }
  .footer-content {
    text-align: center;
  }
  .btn-with-github {
    background: linear-gradient(to bottom, #444, #000);
    border: 0 none;
    transition: none;
    &:hover {
      background: #000;
    }
  }
  .btn-with-google {
    background: linear-gradient(to bottom, #fff, #f8f8f8);
    color: #000;
    border: 0 none;
    transition: none;
    &:hover {
      background: #ececec;
    }
  }
  .copyright {
    font-size: 10px;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>