<template>
  <div>
    <b-list-group horizontal>
      <b-list-group-item active>All</b-list-group-item>
      <b-list-group-item>AP B2C</b-list-group-item>
      <b-list-group-item>AP B2B</b-list-group-item>
      <b-list-group-item>GiiG B2C</b-list-group-item>
      <b-list-group-item>GiiG B2B</b-list-group-item>
      <b-list-group-item>HB</b-list-group-item>
      <b-list-group-item>AF</b-list-group-item>
    </b-list-group>

    <div class="my-4"></div>

    <b-card-group deck>
      <b-card class="col-4 text-center">
        <strong class="display-4 text-success">3.1</strong> ROI this month
      </b-card>
      <b-card>
        <div
          v-if="loading"
          class="d-flex justify-content-center "
        >  
          <b-spinner
            label="Spinning"
            variant="danger"
          ></b-spinner>
        </div>
        <div v-if="!loading && monthly_revenue_data">
          <b-table-simple borderless class="text-center mb-0">
            <b-tbody>
              <b-tr>
                <b-td>${{ Math.round(monthly_revenue_data.SF_yearData['order_total']) | formatNumber }}</b-td>
                <b-td>6.2M</b-td>
                <b-td>5.1M</b-td>
                <b-td>{{ monthly_revenue_data.GA_yearData | formatNumber }}</b-td>
                <b-td>{{ percentageValue(monthly_revenue_data.SF_yearData['order_count'], monthly_revenue_data.GA_yearData) }}</b-td>
                <b-td>${{ (monthly_revenue_data.SF_yearData['order_total'] / monthly_revenue_data.SF_yearData['order_count']) | formatNumber(2) }}</b-td>
                <b-td>{{ monthly_revenue_data.SF_yearData['order_count'] | formatNumber }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot class="text-muted">
              <b-tr>
                <b-td>Revenue</b-td>
                <b-td>Cost</b-td>
                <b-td>Profit</b-td>
                <b-td>Site visits</b-td>
                <b-td>% convert</b-td>
                <b-td>Av $ sale</b-td>
                <b-td>Num orders</b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </b-card>
    </b-card-group>

    <div class="my-4"></div>

    <b-card no-body>
      <b-tabs card>
        <b-tab title="New campaigns" active>
          <b-table-simple borderless>
            <b-thead>
              <b-tr>
                <b-td>Audience</b-td>
                <b-td>Platform</b-td>
                <b-td>Campaign</b-td>
                <b-td>Age</b-td>
                <b-td>Views</b-td>
                <b-td>Clicks</b-td>
                <b-td>Sales</b-td>
                <b-td>Revenue</b-td>
                <b-td>Av $ sale</b-td>
                <b-td>Cost</b-td>
                <b-td>Profit</b-td>
                <b-td>% views-to-click</b-td>
                <b-td>% Click-to-sales</b-td>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr></b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <b-tab title="Alive">
        </b-tab>
        <b-tab title="Killed">
        </b-tab>
      </b-tabs>
    </b-card>

    <div class="my-4"></div>

    <b-card-group deck>
      <b-card>
        <h4><u>Platforms</u></h4>
        <b-table-simple borderless>
          <b-thead>
            <b-tr>
              <b-td></b-td>
              <b-td>Revenue</b-td>
              <b-td>Cost</b-td>
              <b-td>Profit</b-td>
              <b-td>Site visits</b-td>
              <b-td>% convert</b-td>
              <b-td>Av $ sale</b-td>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Facebook</b-td>
            </b-tr>
            <b-tr>
              <b-td>Email</b-td>
            </b-tr>
            <b-tr>
              <b-td>SEO</b-td>
            </b-tr>
            <b-tr>
              <b-td>Google Ads</b-td>
            </b-tr>
            <b-tr>
              <b-td>Instagram</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
      <MonthlyRevenue
        :loading="loading"
        :monthly_revenue_data="monthly_revenue_data"
      />
    </b-card-group>

    <div class="my-4"></div>

    <b-card-group deck>
      <UpsellAnalytics />
      <OrganicSearchAnalytics />
    </b-card-group>
  </div>
</template>
<script>
import Vue from "vue"
import MonthlyRevenue from './Reports/MonthlyRevenue'
import UpsellAnalytics from './Reports/UpsellAnalytics'
import OrganicSearchAnalytics from './Reports/OrganicSearchAnalytics'

export default {
  data() {
    return {
      loading: false,
      monthly_revenue_data: null,
    }
  },
  components: {
    MonthlyRevenue,
    UpsellAnalytics,
    OrganicSearchAnalytics,
  },
  methods: {
    getChangePercentage(first, second) {
      return ((first / second - 1) * 100).toFixed(2) + '%';
    },
    percentageValue(first, second) {
      return ((first / second) * 100).toFixed(2) + '%';
    },
  },
  async mounted() {
    this.loading = true

    this.monthly_revenue_data = await Vue.http.post('salesforce/reports/monthly_revenue_data')
    .then(async (resp) => {
      if (resp.body.success) {
        return Promise.resolve(resp.body.data)
      }
      throw resp.body
    })
    .catch(err => Promise.reject(err))
    .finally(() => this.loading = false)
  },
};
</script>
<style>
</style>
