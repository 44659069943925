<template>
  <component
    :is="tag"
    class="loading-container"
    :class="{'active': loading}"
  >
    <slot name="loading">
      <i v-if="loading" class="fa fa-spinner fa-spin"></i>
    </slot>
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'b-loading',
  props: {
    tag: {
      type: String,
      default: "div"
    },
    loading: Boolean,
  }
};
</script>
<style lang="scss">
  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 6px;
    z-index: 9;
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }
</style>
