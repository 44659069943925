<template>
  <div>
    <p><router-link to="/shopify">Back to Stores</router-link></p>
    <b-card class="store-container">
      <template slot="header">
        <b-card-title>
          <strong>{{title}}</strong>
          <p-button v-if="authUser.is_admin"
            type="success" round size="sm" style="float:right;" @click.native="onEditStore" :disabled="disabled">Edit Store</p-button>
          <p-button v-if="authUser.is_admin"
            type="danger" round size="sm" style="float:right;" @click.native="onDeleteStore" :disabled="disabled">Delete Store</p-button>
        </b-card-title>
      </template>
      <template slot="default">
        <b-container fluid class="py-2" v-if="authUser.is_admin">
          <b-row>
            <b-col lg="6" class="mb-2">
              <activity-logs
                :targetStore="targetStore"
                @updateBackupStats="updateBackupStats"
                @updateRestoreStats="updateRestoreStats"
                @resetBackupStats="resetBackupStats"
                @resetRestoreStats="resetRestoreStats"
              ></activity-logs>
            </b-col>
            <b-col lg="6">
              <b-container fluid>
                <b-form-row>
                  <b-col>
                    <b-form-group label-size="lg" :disabled="disabled" class="mb-0">

                      <template slot="label">
                        <span>Backup History</span>
                        <b-link class="btn_toggle_list ml-2 px-2 py-0" @click.native="onToggleList" :disabled="disabled">{{showDeleted ? 'Hide' : 'Show'}} Deleted Backups</b-link>
                      </template>

                      <b-form-radio-group
                        v-model="backupSelected"
                        :options="activeBackups"
                        stacked
                        name="elm_backup_history"
                        class="backup-history-list"
                      ></b-form-radio-group>

                      <b-container fluid v-show="running && (restoreStats.customer.total || restoreStats.product.total || restoreStats.order.total)">
                        <b-row>
                          <b-col class="text-center">
                            <b-badge pill variant="primary" class="mx-1">Skipped</b-badge>
                            <b-badge pill variant="danger" class="mx-1">Deleted</b-badge>
                            <b-badge pill variant="success" class="mx-1">Created</b-badge>
                            <b-badge pill variant="warning" class="mx-1">Updated</b-badge>
                          </b-col>
                        </b-row>
                        <b-row align-v="center" class="my-2" v-show="running && restoreStats.customer.total">
                          <b-col sm="auto">
                            <h6 class="my-1">Customer</h6>
                          </b-col>
                          <b-col col>
                            <b-progress :max="restoreStats.customer.total" show-value class="my-1" height="1rem">
                              <b-progress-bar variant="primary" :value="restoreStats.customer.skipped"></b-progress-bar>
                              <b-progress-bar variant="danger" :value="restoreStats.customer.deleted"></b-progress-bar>
                              <b-progress-bar variant="success" :value="restoreStats.customer.created"></b-progress-bar>
                              <b-progress-bar variant="warning" :value="restoreStats.customer.updated"></b-progress-bar>
                            </b-progress>
                          </b-col>
                        </b-row>
                        <b-row align-v="center" class="my-2" v-show="running && restoreStats.product.total">
                          <b-col sm="auto">
                            <h6 class="my-1">Product</h6>
                          </b-col>
                          <b-col col>
                            <b-progress :max="restoreStats.product.total" show-value class="my-1" height="1rem">
                              <b-progress-bar variant="primary" :value="restoreStats.product.skipped"></b-progress-bar>
                              <b-progress-bar variant="danger" :value="restoreStats.product.deleted"></b-progress-bar>
                              <b-progress-bar variant="success" :value="restoreStats.product.created"></b-progress-bar>
                              <b-progress-bar variant="warning" :value="restoreStats.product.updated"></b-progress-bar>
                            </b-progress>
                          </b-col>
                        </b-row>
                        <b-row align-v="center" class="my-2" v-show="running && restoreStats.order.total">
                          <b-col sm="auto">
                            <h6 class="my-1">Order</h6>
                          </b-col>
                          <b-col col>
                            <b-progress :max="restoreStats.order.total" show-value class="my-1" height="1rem">
                              <b-progress-bar variant="primary" :value="restoreStats.order.skipped"></b-progress-bar>
                              <b-progress-bar variant="danger" :value="restoreStats.order.deleted"></b-progress-bar>
                              <b-progress-bar variant="success" :value="restoreStats.order.created"></b-progress-bar>
                              <b-progress-bar variant="warning" :value="restoreStats.order.updated"></b-progress-bar>
                            </b-progress>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form-group>
                    <p-button type="info" size="sm" class="mr-2 mt-2" @click.native="onBackupRestore" :disabled="!backupSelected || !is_active || disabled">Restore</p-button>
                    <p-button type="danger" size="sm" class="mr-2 mt-2" @click.native="onBackupRemove" :disabled="!backupSelected || disabled">Delete</p-button>
                    <p-button type="warning" size="sm" class="mr-2 mt-2" @click.native="onBackupActivate" v-show="backupSelected && !is_active && showDeleted" :disabled="disabled">Activate</p-button>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-4">
                  <b-col>
                    <b-form-group label="Backup Your Store" description="Label" label-size="lg" :disabled="disabled">
                      <b-container fluid class="p-0">
                        <b-row no-gutters>
                          <b-col class="mr-3">
                            <b-form-input v-model="label"></b-form-input>
                          </b-col>
                          <b-col sm="auto">
                            <p-button type="success" @click.native="onBackupNow" :disabled="!label || disabled">Backup NOW</p-button>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form-group>

                    <b-progress :max="backupStats.customer.total" :striped="backupStats.customer.current != backupStats.customer.total" :animated="backupStats.customer.current != backupStats.customer.total" v-show="running && backupStats.customer.total" class="my-2">
                      <b-progress-bar :value="backupStats.customer.current">
                        Customer: {{backupStats.customer.current}} / {{backupStats.customer.total}}
                      </b-progress-bar>
                    </b-progress>
                    <b-progress :max="backupStats.product.total" :striped="backupStats.product.current != backupStats.product.total" :animated="backupStats.product.current != backupStats.product.total" v-show="running && backupStats.product.total" class="my-2">
                      <b-progress-bar :value="backupStats.product.current">
                        Product: {{backupStats.product.current}} / {{backupStats.product.total}}
                      </b-progress-bar>
                    </b-progress>
                    <b-progress :max="backupStats.order.total" :striped="backupStats.order.current != backupStats.order.total" :animated="backupStats.order.current != backupStats.order.total" v-show="running && backupStats.order.total" class="my-2">
                      <b-progress-bar :value="backupStats.order.current">
                        Order: {{backupStats.order.current}} / {{backupStats.order.total}}
                      </b-progress-bar>
                    </b-progress>
                  </b-col>
                </b-form-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid class="py-2" v-if="!authUser.is_admin">
          <generate-xml
            :targetStore="targetStore"
          ></generate-xml>
        </b-container>
        <b-container fluid class="py-2">
          <store-logs
            :targetStore="targetStore"
          ></store-logs>
        </b-container>
        <b-loading :loading="loading" />
      </template>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ModalBus from '@/buses/modal'

import ActivityLogs from './ActivityLogs';
import GenerateXML from './GenerateXML';
import StoreLogs from './StoreLogs';

export default {
  components: {
    ActivityLogs,
    'generate-xml': GenerateXML,
    'store-logs': StoreLogs,
  },
  data() {
    return {
      // title: "Store",
      showDeleted: false,
      processing: false,
      backupSelected: null,
      label: '',
      backupStats: {
        customer: {
          total: 0,
          current: 0,
        },
        product: {
          total: 0,
          current: 0,
        },
        order: {
          total: 0,
          current: 0,
        },
      },
      restoreStats: {
        customer: {
          total: 0,
          deleted: 0,
          updated: 0,
          created: 0,
          skipped: 0,
        },
        product: {
          total: 0,
          deleted: 0,
          updated: 0,
          created: 0,
          skipped: 0,
        },
        order: {
          total: 0,
          deleted: 0,
          updated: 0,
          created: 0,
          skipped: 0,
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      'authUser': 'user',
    }),
    ...mapGetters('shopify/stores', [
      'store',
      'loading',
      'backups',
      'targetStore',
    ]),
    activeBackups() {
      return this.backups.filter(backup => backup.is_active || this.showDeleted).map(backup => ({ html: `<span class="${backup.is_active ? '' : 'deleted'}">${backup.label} <span class="created">${moment(backup.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span></span>`, value: { id: backup.id, is_active: backup.is_active }, disabled: backup.is_running })) || []
    },
    running() {
      return !!(this.backups.sort((a, b) => b.id - a.id).pop() || {}).is_running
    },
    disabled() {
      return this.processing || this.loading || this.running
    },
    storeName() {
      return this.store && this.store.name || ''
    },
    title() {
      return `Store - ${this.storeName}`
    },
    storeUrl() {
      return `https://${this.storeName}.myshopify.com`
    },
    is_active() {
      return this.backupSelected && this.backupSelected.is_active
    },
  },
  methods: {
    onEditStore() {
      this.store && ModalBus.$emit('shopifyEditStoreModal.open', this.store)
    },
    onDeleteStore() {
      this.store && ModalBus.$emit('shopifyDeleteStoreModal.open', this.store)
    },
    onBackupNow() {
      if (confirm('Do you really want to start backup this store?')) {
        this.$store.dispatch('shopify/stores/backupInit', {
          name: this.store.name,
          label: this.label,
        }).then(() => {
          this.$notify({
            message: `Backup <${this.label}> started ...`,
            type: 'info',
          })
          this.label = ''
        })
      }
    },
    onBackupRestore() {
      this.store && ModalBus.$emit('shopifyBackupRestoreModal.open', {
        store: this.store,
        backup: this.backupSelected,
      })
      // if (confirm('Do you really want to revert your store with this backup?')) {
      //   this.$store.dispatch('shopify/stores/backupRestore', {
      //     id: this.backupSelected.id,
      //   }).then(() => this.backupSelected = null)
      // }
    },
    onBackupRemove() {
      if (this.backupSelected) {
        if (this.backupSelected.is_active && confirm('Do you really want to remove this backup set?')) {
          this.$store.dispatch('shopify/stores/backupRemove', {
            id: this.backupSelected.id,
          }).then(() => this.backupSelected = null)
        }
        if (!this.backupSelected.is_active && confirm('Do you really want to delete backup data permanently?')) {
          this.$store.dispatch('shopify/stores/backupRemove', {
            id: this.backupSelected.id,
            force: true,
          }).then(() => this.backupSelected = null)
        }
      }
    },
    onBackupActivate() {
      if (this.backupSelected && !this.backupSelected.is_active && confirm('Do you really want to activate this backup?')) {
        this.$store.dispatch('shopify/stores/backupActivate', {
          id: this.backupSelected.id,
        }).then(() => this.backupSelected = null)
      }
    },
    onToggleList() {
      this.showDeleted = !this.showDeleted
      if (!this.showDeleted && this.backupSelected && !this.backupSelected.is_active) {
        this.backupSelected = null
      }
    },
    updateBackupStats({resource, stats}) {
      this.backupStats[resource] = stats
    },
    updateRestoreStats({resource, stats}) {
      this.restoreStats[resource] = stats
    },
    resetBackupStats() {
      this.backupStats = {
        customer: {
          total: 0,
          current: 0,
        },
        product: {
          total: 0,
          current: 0,
        },
        order: {
          total: 0,
          current: 0,
        },
      }
    },
    resetRestoreStats() {
      this.restoreStats = {
        customer: {
          total: 0,
          deleted: 0,
          updated: 0,
          created: 0,
          skipped: 0,
        },
        product: {
          total: 0,
          deleted: 0,
          updated: 0,
          created: 0,
          skipped: 0,
        },
        order: {
          total: 0,
          deleted: 0,
          updated: 0,
          created: 0,
          skipped: 0,
        },
      }
      this.$store.dispatch('shopify/stores/backupRestoreCompleted');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.store) return next()
    const answer = window.confirm('Do you really want to leave?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
<style lang="scss">
.store-container {
  .card-header {
    border-bottom: solid 1px #ddd;
    .btn {
      margin-left: 15px;
    }
  }
  .card-body {
    // max-height: calc(100vh - 300px);
    overflow: auto;
  }
  .store-status {
  }
  .btn_toggle_list {
    font-size: 12px;
  }
  .backup-history-list {
    outline: 0 none;
    overflow: auto;
    max-height: 320px;
    .custom-control {
      padding-left: 2rem;
      margin-bottom: 5px;
      cursor: pointer;
      label {
        color: #212529;
        cursor: pointer;
        margin: 0;
        .deleted {
          text-decoration: line-through;
          opacity: 0.3;
        }
        .created {
          font-size: 10px;
          transition: all 0.15s ease;
          opacity: 0.7;
          &:before {
            content: 'at ';
          }
        }
        > span {
          &:hover .created {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>