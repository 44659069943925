<template>
  <b-modal
    class="addStoreModal"
    hide-header
    hide-footer
    centered
    size="lg"
    ref="addStoreModal"
    @open="open"
  >
    <b-form @submit="onSubmit" @reset="onReset" class="p-4">
      <b-form-group
        label="Store Name"
        description="e.g. {store name}.myshopify.com"
        :formatter="formatForLowerCase"
      >
        <b-form-input
          v-model="form.name"
          :state="form.name.length > 0 && form.name.length <= 255"
          placeholder="Store Name"
        />
      </b-form-group>
      <b-form-group
        label="API key"
      >
        <b-form-input
          v-model="form.api_key"
          :state="form.api_key.length > 0 && form.api_key.length <= 255"
          placeholder="API key"
        />
      </b-form-group>
      <b-form-group
        label="Password"
      >
        <b-form-input
          v-model="form.password"
          :state="form.password.length > 0 && form.password.length <= 255"
          placeholder="Password"
        />
      </b-form-group>
      <b-form-group
        label="Example URL"
        description="Private applications authenticate with Shopify through basic HTTP authentication, using the URL format https://{apikey}:{password}@{hostname}/admin/api/{version}/{resource}.json"
      >
        <b-form-input
          v-model="exampleURL"
          readonly
        />
      </b-form-group>
      <b-form-group
        label="Shared Secret"
        description="(optional)"
      >
        <b-form-input
          v-model="form.shared_secret"
          placeholder="Shared Secret"
        />
      </b-form-group>
      <b-row class="form-actions">
        <b-col>
          <p-button type="success" round size="sm" class="pull-right" :disabled="hasError() || processing" @click.native="onSubmitForm" :loading="processing">Add a Store</p-button>
          <p-button type="danger" round size="sm" class="pull-right mr-2" @click.native="onCancel">Cancel</p-button>
          <span class="pull-right mr-4 error text-danger"></span>
        </b-col>
      </b-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      processing: false,
      form: {
        name: '',
        api_key: '',
        password: '',
        shared_secret: '',
        error: true,
      },
    }
  },
  watch: {
    form: {
      handler() {
        this.validate()
      },
      deep: true
    },
  },
  computed: {
    exampleURL: function() {
      var version = '2019-10';
      var resource = 'orders';
      // if (!this.form.api_key && !this.form.password && !this.form.name) return ''
      return `https://${this.form.api_key}:${this.form.password}@${this.form.name}/admin/api/${version}/${resource}.json`
    }
  },
  methods: {
    open() {
      this.resetFormFields()
      this.$refs.addStoreModal.show()
    },
    close() {
      this.$refs.addStoreModal.hide()
    },
    validate() {
      this.form.error = [
        !this.form.name,
        !this.form.api_key,
        !this.form.password,
      ].some(error => error)
    },
    hasError() {
      return this.form.error
    },
    onSubmit() {
      this.onSubmitForm()
    },
    onCancel() {
      this.close()
    },
    onReset() {
      this.resetFormFields()
    },
    resetFormFields() {
      this.form = {
        name: '',
        api_key: '',
        password: '',
        shared_secret: '',
        error: true,
      }
    },
    onSubmitForm(e) {
      e && e.preventDefault()
      if (!this.hasError()) {
        this.processing = true
        const {error, ...payload} = this.form
        this.$store.dispatch('shopify/stores/add', payload)
        .then(() => {
          this.$notify(`Store <${payload.name}> has been successfully added.`)
          this.close()
        })
        .catch(e => {
          if (e.status == 404 && e.body.code == 'invalid') {
            this.$notify({
              message: 'Store configuration is incorrect. Please try with another one.',
              type: 'danger',
            })
          } else {
            if (e.body.code == 'duplicate') {
              this.$notify({
                message: 'This store is already added.',
                type: 'info',
              })
            } else {
              this.$notify({
                message: 'This store could not be added... Try again later',
                type: 'danger',
              })
            }
            this.close()
          }
        })
        .finally(() => {
          this.processing = false
        })
      }
    },
    formatForLowerCase(value, event) {
      return value.toLowerCase()
    },
  },
  created() {
    this.resetFormFields()
  },
}
</script>
