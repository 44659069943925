import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from '../store'
Vue.use(VueRouter)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters['auth/isLoggedIn']
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  if (!isLoggedIn && requiresAuth) {
    next('/login');
    return
  }

  if (isLoggedIn && (to.name === 'login')) {
    next('/')
    return
  }

  let authUser = store.getters['auth/user'];
  if (requiresAdmin && (!authUser || !authUser.is_admin)) {
    store.dispatch('notify', {
      message: 'You are not allowed to manage Shopify feature.',
      type: 'danger',
    })
    next('/')
    return
  }

  next();
})

export default router
