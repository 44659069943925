import Notify from "vue-notifyjs";
import SideBar from "@/components/SidebarPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import "es6-promise/auto";

//css assets
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/sass/paper-dashboard.scss";
import "@/assets/css/themify-icons.css";

import { BootstrapVue } from 'bootstrap-vue';

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(BootstrapVue);
    Vue.use(SideBar);
    Vue.use(Notify, {
      horizontalAlign: 'right',
      verticalAlign: 'top',
    });
  }
}
