<template>
  <div style="position: relative; z-index: 9;">
    <b-card class="stores-container">
      <template slot="header">
        <b-card-title>
          <strong>{{title}}</strong>
          <p-button v-if="authUser.is_admin"
            type="success" round size="sm" style="float:right;" @click.native="onAddStore">Add a Store</p-button>
        </b-card-title>
        <p v-if="authUser.is_admin"
          class="card-category my-4">To add a store, you will need to create a private app first. <a href="https://help.shopify.com/en/manual/apps/private-apps" target="_blank">See details here</a>.</p>
      </template>
      <template slot="default">
        <template v-for="store in filteredStores">
          <b-container fluid class="store-row py-2">
            <b-row>
              <b-col class="d-flex justify-content-between align-items-center my-2">
                <div>
                  <h3 class="mt-0">{{store.name}}</h3>
                  <a :href="`https://${store.name}.myshopify.com`" target="_blank">See it live</a>
                </div>
                <b-button variant="outline-primary" @click="onSelectStore(store)">Select</b-button>
              </b-col>
            </b-row>
            <b-row v-if="authUser.is_admin">
              <b-col class="my-2">
                Latest Activity:
              </b-col>
            </b-row>
          </b-container>
        </template>
        <b-loading :loading="loading" />
      </template>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalBus from '@/buses/modal'

export default {
  components: {
  },
  data() {
    return {
      title: "Stores",
      processing: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      'authUser': 'user',
    }),
    ...mapGetters('shopify/stores', [
      'stores',
      'loading',
    ]),
    filteredStores() {
      return this.stores.filter(store => this.authUser.is_admin || store.is_marketing)
    },
  },
  methods: {
    onAddStore() {
      ModalBus.$emit('shopifyAddStoreModal.open')
    },
    onSelectStore(store) {
      this.$router.push(`/shopify/${store.name}`)
    },
  },
  mounted() {
    // this.$bvToast.toast('Test Message.', {
    //   title: `Title`,
    //   variant: 'custom',
    //   autoHideDelay: 2000,
    //   solid: true
    // })
  }
}
</script>
<style lang="scss">
.stores-container {
  .card-body {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
  .store-row {
    border-top: solid 1px #ddd;
  }
}
</style>
