import Vue from 'vue'
import Vuex from 'vuex'
import { Notification } from "vue-notifyjs"
Vue.use(Vuex)

import modules from './modules'

const initialState = () => ({
  error: null,
  loading: false,
  appTitle: 'Ecofibre',
  apiHost: process.env.VUE_APP_API_HOST,
})

export default new Vuex.Store({
  state: initialState,
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
  },
  actions: {
    notify({}, payload) {
      Notification.notify(payload)
    }
  },
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    appTitle: state => state.appTitle,
    apiHost: state => state.apiHost || 'https://api.ecofibre.com',
  },
  modules,
})
