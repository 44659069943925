<template>
  <nav class="navbar navbar-expand-lg navbar-light" v-if="isLoggedIn">
    <div class="container-fluid">
      <a class="navbar-brand">{{routeName}}</a>
      <button class="navbar-toggler navbar-burger"
              type="button"
              @click="toggleSidebar"
              :aria-expanded="$sidebar.showSidebar"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="javascript:void(0)" @click="logout" class="nav-link">Log Out <span style="text-transform: initial;">({{authUser.name}})</span></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    routeName() {
      return '';
      // const { name } = this.$route;
      // return this.capitalizeFirstLetter(name);
    },
    ...mapGetters('auth', {
      'authUser': 'user',
      'isLoggedIn': 'isLoggedIn',
    }),
  },
  data() {
    return {
      activeNotifications: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch('auth/logout')
    }
  }
};
</script>
<style>
</style>
