<template>
  <div class="store-logs-wrapper">
    <hr/>
    <h3>Transaction Errors</h3>
    <div class="store-logs-toolbar">
      <b-button class="mr-2" variant="outline-primary" @click="loadLogs()"><template v-if="logs.length > 0">Refresh Logs</template><template v-else>Load Logs</template></b-button>
    </div>
    <div class="my-4 store-logs-content">
      <div class="store-logs-list">
        <b-table
          hover
          :items="logs"
          :fields="fields"
          v-show="logs.length > 0"
        >
          <template #cell(order_number)="data">
            {{ data.item.payload.name }}
          </template>
          <template #cell(order_date)="data">{{ moment(data.item.payload.created_at).format('YYYY-MM-DD HH:mm:ss') }}</template>
          <template #cell(reason)="data">
            <ul v-if="data.item.reason.length > 0">
              <li class="text-success" v-for="(r, index) in data.item.reason" :key="index">{{ r }}</li>
            </ul>
            <p-button size="sm"
              @click.native="data.item.reason_expanded = !data.item.reason_expanded"
            >
              {{ data.item.reason_expanded ? 'Hide' : 'Show' }} Full Error Log
            </p-button>
            <b-collapse v-model="data.item.reason_expanded" class="mt-2">
              <pre>{{ data.item.content.data }}</pre>
            </b-collapse>
          </template>
          <template #cell(events)="data">
            <p-button size="sm"
              @click.native="toggleOrderEvents(data)"
            >
              {{ data.item.events_expanded ? 'Hide' : 'Show' }} Timeline
            </p-button>
          </template>
          <template #cell(actions)="data">
            <p-button type="success" @click.native="submitOrder(data.item.order_id)">Submit</p-button>
          </template>

          <template #row-details="row">
            <ul v-if="row.item.events" class="order_events">
              <li v-for="event in row.item.events">
                <div class="order_event">
                  <span class="event_message" v-html="event.message"></span><span class="event_at">{{ moment(event.created_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </li>
            </ul>
          </template>
        </b-table>
      </div>
    </div>
    <b-loading :loading="loading || logsLoading" />
  </div>
</template>
<script>
import Vue from "vue"
import { mapGetters } from 'vuex'
export default {
  props: ['targetStore'],
  data() {
    return {
      logsLoading: false,
      logs: [],
      fields: [
        {
          key: 'order_id',
          sortable: true,
          label: 'Order ID',
        },
        {
          key: 'order_number',
          sortable: true,
          label: 'Order Number',
        },
        {
          key: 'order_date',
          sortable: true,
          label: 'Order Date',
        },
        {
          key: 'reason',
          sortable: false,
        },
        {
          key: 'events',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', {
      'authUser': 'user',
    }),
    ...mapGetters('shopify/stores', [
      'store',
      'loading',
    ]),
    storeName: function() {
      return this.targetStore || this.store.name
    },
  },
  methods: {
    async loadLogs() {
      this.logsLoading = true

      const logs = await Vue.http.post('admin/shopify/store/getLogs', {
        name: this.storeName,
      })
      .then(async (resp) => {
        if (resp.body.success) {
          return Promise.resolve(resp.body.data.logs)
        }
        throw resp.body
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.logsLoading = false)

      this.logs = logs.filter(log => log.order_id).map(log => {
        try {
          log.payload = JSON.parse(log.payload)
          log.reason = []
          log.reason_expanded = false
          if (log.content.includes('This product cannot be added to a VW Order')) {
            log.reason.push('Non-VW product is added to VW Order')
          }
          if (log.content.includes('No such column')) {
            log.reason.push('Salesforce missing field')
          }
          if (log.content.includes('[PricebookEntryId]')) {
            log.reason.push('PricebookEntry is missing')
          }
          if (log.content.includes('duplicate value found: Shopify_Order_ID__c duplicates value on record with')) {
            log.reason.push('Order would be synced to SF already, might be a garbage error log which can be cleared with re-submit.')
          }
          log.content = JSON.parse(log.content)
          log.events = [];
        } catch(e) {}
        return log;
      }).sort((a, b) => b.order_id - a.order_id)
    },
    async toggleOrderEvents(data) {
      data.item.events_expanded = !data.item.events_expanded;
      data.toggleDetails();
      if (data.item.events_expanded) {
        this.logsLoading = true

        data.item.events = await Vue.http.post('admin/shopify/store/getOrderEvents', {
          name: this.storeName,
          order_id: data.item.order_id,
        })
        .then(async (resp) => {
          if (resp.body.success) {
            return Promise.resolve(resp.body.data.events.sort((a, b) => b.id - a.id))
          }
          throw resp.body
        })
        .catch(err => Promise.reject(err))
        .finally(() => this.logsLoading = false)
      }
    },
    async submitOrder(order_id) {
      if (!confirm('Do you want to re-submit this order payload?')) return;
      this.logsLoading = true
      const order = this.logs.find(order => order.order_id == order_id)
      if (!order) return;
      const url = order.source.split('.').join('/')
      await Vue.http.post(url, order.payload);
      await this.loadLogs();
    }
  }
}
</script>
<style lang="scss">
  .store-logs-wrapper {
    pre {
      white-space: pre-wrap;
    }
    .order_events {
      .order_event {
        display: flex;
        justify-content: space-between;
        .event_message {
          flex: 1;
        }
        .event_at {
          white-space: nowrap;
          flex: 0 0 auto;
        }
      }
    }
  }
</style>