<template>
  <div class="logs-wrapper">
    <div class="logs-container" id="logs-container">
    </div>
    <div class="actions">
      <p-button type="info" round size="sm" style="" @click.native="onClearLogs">Clear</p-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: ['targetStore'],
  data() {
    return {
      logs: [],
      sseSource: null
    }
  },
  computed: {
    ...mapGetters('shopify/stores', [
      'store',
      'loading',
    ]),
    ...mapGetters([
      'apiHost',
    ]),
    storeName: function() {
      return this.targetStore || this.store.name
    },
  },
  watch: {
    targetStore(after) {
      if (after) {
        this.destroyEventListener()
      }
      this.initEventListener()
    }
  },
  methods: {
    onClearLogs() {
      // this.logs = []
      var container = document.getElementById('logs-container');
      if (container) {
        container.innerHTML = ''
      }
    },
    logClass(log) {
      if (log.type == 'backup.completed' || log.type == 'restore.completed' || log.type == 'backup.removed' || log.type == 'backup.activated') {
        return ['success'];
      }
      const classes = []
      if (log.status == 'error') {
        classes.push('error')
      }
      return classes
    },
    writeLog(log) {
      var container = document.getElementById('logs-container');
      if (container) {
        container.innerHTML += `<p class="${this.logClass(log).join(' ')}">${moment(log.at).format('HH:mm:ss')} - ${log.data}</p>`
      }
    },
    initEventListener() {
      this.sseSource = new EventSource(this.apiHost + '/admin/shopify/status/' + this.storeName)
      this.sseSource.addEventListener('message', (e) => {
        try {
          const log = JSON.parse(e.data)
          switch (log.type) {
            case 'ping':
              // console.log(log)
              break

            case 'backup.task.completed':
              console.log('Resource completed event', log)
              break
            case 'restore.task.completed':
              console.log('Resource completed event', log)
              break

            case 'backup.completed':
              this.$notify({
                message: `Backup <${log.data.label}> finished.`,
                type: 'info',
              })
              setTimeout(() => {
                this.$store.dispatch('shopify/stores/getBackups', this.storeName)
              }, 1000 * 1)
              this.writeLog({
                at: log.at,
                type: log.type,
                data: `Backup completed successfully with ${log.data.tasks.customer.stats.saved}/${log.data.tasks.customer.stats.total} customers, ${log.data.tasks.product.stats.saved}/${log.data.tasks.product.stats.total} products, ${log.data.tasks.order.stats.saved}/${log.data.tasks.order.stats.total} orders.`,
              })
              this.$emit('resetBackupStats')
              break

            case 'backup.removed':
              this.$notify({
                message: `Backup <${log.data.label}> removed.`,
                type: 'info',
              })
              this.$store.dispatch('shopify/stores/getBackups', this.storeName)
              this.writeLog({
                at: log.at,
                type: log.type,
                data: `Backup <${log.data.label}> removed successfully.`,
              })
              break

            case 'backup.activated':
              this.$notify({
                message: `Backup <${log.data.label}> activated.`,
                type: 'info',
              })
              this.$store.dispatch('shopify/stores/getBackups', this.storeName)
              this.writeLog({
                at: log.at,
                type: log.type,
                data: `Backup <${log.data.label}> activated successfully.`,
              })
              break

            case 'restore.completed':
              this.$notify({
                message: `Backup <${log.data.label}> is now restored at Store<${this.storeName}>`,
              })
              setTimeout(() => {
                this.$store.dispatch('shopify/stores/getBackups', this.storeName)
              }, 1000 * 1)
              this.writeLog({
                at: log.at,
                type: log.type,
                data: `Restore completed successfully with ${log.data.tasks.customer.stats.total} customers, ${log.data.tasks.product.stats.total} products, ${log.data.tasks.order.stats.total} orders.`,
              })
              this.$emit('resetRestoreStats')
              break

            case 'backup.progress':
              this.$emit('updateBackupStats', {
                resource: log.resource,
                stats: {
                  total: log.total,
                  current: log.current,
                },
              })
              break
            case 'restore.progress':
              this.$emit('updateRestoreStats', {
                resource: log.resource,
                stats: log.stats,
              })
              break

            default:
              // this.writeLog(log)
              break
          }
          this.$nextTick(() => {
            var container = this.$el.querySelector(".logs-container")
            container.scrollTop = container.scrollHeight + 30
          })
        } catch(e) {
          console.log(e)
        }
      })
      // this.sseSource.addEventListener('error', (e) => {
      //   console.log("EventSource failed: ", e)
      // })
    },
    destroyEventListener() {
      this.sseSource.close()
      this.sseSource = null
    }
  },
  mounted() {
    this.initEventListener()
  },
  beforeDestroy() {
    this.destroyEventListener()
  },
}
</script>
<style lang="scss">
  .logs-wrapper {
    min-height: 350px;
    height: 100%;
    position: relative;
    max-height: calc(100vh - 350px);
    .logs-container {
      height: 100%;
      overflow: auto;
      background: #fff;
      border: inset 2px #efefef;
      padding: 4px 1rem;
    }
    p {
      font-size: 11px;
      font-family: sans-serif;
      color: #000;
      line-height: 1.2;
      margin: 1px 0;
      &.error {
        color: red;
        font-weight: 600;
      }
      &.success {
        color: green;
        font-weight: 600;
      }
    }
    .actions {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
</style>