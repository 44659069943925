<template>
  <div class="wrapper wrapper-full-page section content">
    <b-container>
      <b-row align-v="center">
        <b-col md="8" offset-md="2" class="text-center">
          <h2 class="title text-danger">404 Not Found</h2>
          <h2 class="title">Oops! It seems that this page does not exist.</h2>
          <a href="javascript:history.back()">Go back!</a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>
