import Vue from 'vue'

export default {
  load({commit}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/logs', payload)
      .then(resp => {
        const {logs} = resp.data
        commit('load', {logs})
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    });;
  },
}
