export default {
  add(state, {store}) {
    state.list.push(store)
  },
  remove(state, {id, name}) {
    let index = state.list.findIndex(store => store.id == id || store.name == name)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  update(state, newStore) {
    let index = state.list.findIndex(store => store.id == newStore.id || store.name == newStore.name)
    if (index > -1) {
      state.list.splice(index, 1, newStore)
    }
  },
  load(state, {stores}) {
    state.list = stores
    state.loading = false
    state.loaded = true
  },
  loading(state, loading) {
    state.loading = loading
  },
  setActiveStore(state, store) {
    state.activeStore = store
  },
  setBackups(state, backups) {
    state.backups = backups //.filter(backup => backup.is_active)
  },
  running(state, running) {
    state.running = running
  },
  setTargetStore(state, targetStore) {
    state.targetStore = targetStore
  },
}
