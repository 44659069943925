<template>
  <div class="shopify-content-wrapper">
    <router-view></router-view>
    <div class="modals">
      <shopify-add-store-modal
        ref="shopifyAddStoreModal"
      />
      <shopify-edit-store-modal
        ref="shopifyEditStoreModal"
      />
      <shopify-delete-store-modal
        ref="shopifyDeleteStoreModal"
      />
      <shopify-backup-restore-modal
        ref="shopifyBackupRestoreModal"
      />
    </div>
  </div>
</template>
<script>
import ModalBus from '@/buses/modal'
import ShopifyAddStoreModal from '@/modals/Shopify/AddStore'
import ShopifyEditStoreModal from '@/modals/Shopify/EditStore'
import ShopifyDeleteStoreModal from '@/modals/Shopify/DeleteStore'
import ShopifyBackupRestoreModal from '@/modals/Shopify/BackupRestore'

export default {
  components: {
    /* modals */
    ShopifyAddStoreModal,
    ShopifyEditStoreModal,
    ShopifyDeleteStoreModal,
    ShopifyBackupRestoreModal,
  },
  created() {
    ModalBus.$on('shopifyAddStoreModal.open', () => {
      if (this.$refs.shopifyAddStoreModal) {
        this.$refs.shopifyAddStoreModal.open()
      }
    })
    ModalBus.$on('shopifyEditStoreModal.open', (store) => {
      if (this.$refs.shopifyEditStoreModal) {
        this.$refs.shopifyEditStoreModal.open(store)
      }
    })
    ModalBus.$on('shopifyDeleteStoreModal.open', (store) => {
      if (this.$refs.shopifyDeleteStoreModal) {
        this.$refs.shopifyDeleteStoreModal.open(store)
      }
    })
    ModalBus.$on('shopifyBackupRestoreModal.open', (params) => {
      if (this.$refs.shopifyBackupRestoreModal) {
        this.$refs.shopifyBackupRestoreModal.open(params)
      }
    })
  },
  beforeDestroy() {
    ModalBus.$off('shopifyAddStoreModal.open')
    ModalBus.$off('shopifyEditStoreModal.open')
    ModalBus.$off('shopifyDeleteStoreModal.open')
    ModalBus.$off('shopifyBackupRestoreModal.open')
  }
};
</script>
<style>
</style>
