import store from '../store'

// Layouts
import DashboardLayout from "@/layout/dashboard/DashboardLayout";
import AuthLayout from "@/layout/auth/AuthLayout";

// GeneralViews
import NotFound from "@/pages/NotFoundPage";

// Dashboard pages
import Dashboard from "@/pages/Dashboard";

// Shopify pages
import ShopifyContent from "@/layout/dashboard/ShopifyContent";
import ShopifyStores from "@/pages/Shopify/Stores";
import ShopifyStore from "@/pages/Shopify/Store";

// Reports Page
import Reports from "@/pages/Reports";

// ADs Generator
import ADsGenerator from "@/pages/ADsGenerator";

// Auth pages
import Login from "@/pages/Auth/Login";
import Logout from "@/pages/Auth/Logout";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "shopify",
        // name: "shopify",
        component: ShopifyContent,
        meta: {
          // requiresAdmin: true
        },
        beforeEnter: async (to, from, next) => {
          await store.dispatch('shopify/stores/load')
          next()
        },
        children: [
          {
            path: ":name(.+)",
            name: "store",
            component: ShopifyStore,
            beforeEnter: async (to, from, next) => {
              // console.log(to)
              try {
                await store.dispatch('shopify/stores/setActiveByName', to.params.name)
              } catch(e) {
                console.log(e)
              }
              if (!store.getters['shopify/stores/store']) {
                next('/shopify')
              } else {
                next()
              }
            },
          },
          {
            path: '/',
            name: 'stores',
            component: ShopifyStores
          },
        ],
      },
      {
        path: "shopify-audit-logs",
        name: "shopify-audit-logs",
        component: () => import("@/pages/Shopify/ActionLogs"),
      },
      {
        path: "reports",
        name: "reports",
        meta: {
          // requiresAdmin: true
        },
        component: Reports
      },
      {
        path: "ads-generator",
        name: "ads-generator",
        meta: {
          // requiresAdmin: true
        },
        component: ADsGenerator
      },
      {
        path: "find-dead-links",
        name: "find-dead-links",
        meta: {
          // requiresAdmin: true
        },
        component: () => import("@/pages/FindDeadLinks"),
      },
      {
        path: "spell-check-whitelist",
        name: "spell-check-whitelist",
        meta: {
          // requiresAdmin: true
        },
        component: () => import("@/pages/SpellCheckWhitelist"),
      },
    ]
  },
  {
    path: '/',
    name: 'auth',
    component: AuthLayout,
    redirect: 'login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'login_sso',
        name: 'login_sso',
        component: () => import("@/pages/Auth/LoginSSO"),
      },
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
  { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
