<template>
  <b-modal
    class="editStoreModal"
    hide-header
    hide-footer
    centered
    size="lg"
    ref="editStoreModal"
    @open="open"
  >
    <b-form @submit="onSubmit" @reset="onReset" class="p-4">
      <b-form-group
        label="Store Name"
        description="e.g. {store name}.myshopify.com"
        :formatter="formatForLowerCase"
      >
        <b-form-input
          v-model="form.name"
          readonly
          :state="form.name.length > 0 && form.name.length <= 255"
          placeholder="Store Name"
        />
      </b-form-group>
      <b-form-group
        label="API key"
      >
        <b-form-input
          v-model="form.api_key"
          :state="form.api_key.length > 0 && form.api_key.length <= 255"
          placeholder="API key"
        />
      </b-form-group>
      <b-form-group
        label="Password"
      >
        <b-form-input
          v-model="form.password"
          :state="form.password.length > 0 && form.password.length <= 255"
          placeholder="Password"
        />
      </b-form-group>
      <b-form-group
        label="Example URL"
        description="Private applications authenticate with Shopify through basic HTTP authentication, using the URL format https://{apikey}:{password}@{hostname}/admin/api/{version}/{resource}.json"
      >
        <b-form-input
          v-model="exampleURL"
          readonly
        />
      </b-form-group>
      <b-form-group
        label="Shared Secret"
        description="(optional)"
      >
        <b-form-input
          v-model="form.shared_secret"
          placeholder="Shared Secret"
        />
      </b-form-group>
      <b-form-group
        label="Is this store for marketing?"
      >
        <b-form-checkbox
          v-model="form.is_marketing"
        >
          Rakuten XML
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Automatic Backup Schedule"
        description="Leave this empty to disable automatic backup. Or set cron schedule expression  e.g. Cron Schedule Expressions (https://crontab.guru)"
      >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-form-checkbox class="mr-n2" v-model="schedule" @change="toggleSchedule">
              <span class="sr-only">Checkbox for following text input</span>
            </b-form-checkbox>
          </b-input-group-prepend>
          <b-form-input
            :disabled="form.schedule === null"
            v-model="form.schedule"
            :state="cronScheduleStatus"
            placeholder="Cron Schedule Expressions"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Automatic Template Backup Schedule"
        description="Leave this empty to disable automatic template backup. Or set cron schedule expression  e.g. Cron Schedule Expressions (https://crontab.guru)"
      >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-form-checkbox class="mr-n2" v-model="template_schedule" @change="toggleTemplateSchedule">
              <span class="sr-only">Checkbox for following text input</span>
            </b-form-checkbox>
          </b-input-group-prepend>
          <b-form-input
            :disabled="form.template_schedule === null"
            v-model="form.template_schedule"
            :state="cronTemplateScheduleStatus"
            placeholder="Cron Schedule Expressions"
          />
        </b-input-group>
      </b-form-group>
      <b-row class="form-actions">
        <b-col>
          <p-button type="success" round size="sm" class="pull-right" :disabled="hasError() || processing" @click.native="onSubmitForm" :loading="processing">Update</p-button>
          <p-button type="danger" round size="sm" class="pull-right mr-2" @click.native="onCancel">Cancel</p-button>
          <span class="pull-right mr-4 error text-danger"></span>
        </b-col>
      </b-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import cronstrue from 'cronstrue'

export default {
  data() {
    return {
      processing: false,
      schedule: false,
      template_schedule: false,
      initial: {
      },
      form: {
        name: '',
        api_key: '',
        password: '',
        shared_secret: '',
        is_marketing: false,
        schedule: '',
        template_schedule: '',
        error: true,
      },
    }
  },
  watch: {
    form: {
      handler() {
        this.validate()
      },
      deep: true
    },
    schedule: function() {
      this.validate()
    },
    template_schedule: function() {
      this.validate()
    },
  },
  computed: {
    ...mapGetters([
    ]),
    exampleURL: function() {
      var version = '2019-10';
      var resource = 'orders';
      // if (!this.form.api_key && !this.form.password && !this.form.name) return ''
      return `https://${this.form.api_key}:${this.form.password}@${this.form.name}/admin/api/${version}/${resource}.json`
    },
    cronScheduleStatus: function() {
      if (!this.schedule) return true
      try {
        var explainString = cronstrue.toString(this.form.schedule || '')
        // console.log(explainString)
        return true
      } catch(e) {
        return false
      }
    },
    cronTemplateScheduleStatus: function() {
      if (!this.template_schedule) return true
      try {
        var explainString = cronstrue.toString(this.form.template_schedule || '')
        // console.log(explainString)
        return true
      } catch(e) {
        return false
      }
    },
  },
  methods: {
    open(store) {
      this.initial = {
        name: store.name,
        api_key: store.api_key,
        password: store.password,
        shared_secret: store.shared_secret,
        is_marketing: store.is_marketing,
        schedule: store.schedule,
        template_schedule: store.template_schedule,
      }
      this.resetFormFields()
      this.$refs.editStoreModal.show()
    },
    close() {
      this.$refs.editStoreModal.hide()
    },
    validate() {
      this.form.error = [
        !this.form.name,
        !this.form.api_key,
        !this.form.password,
        !this.cronScheduleStatus,
        !this.cronTemplateScheduleStatus,
      ].some(error => error)
    },
    hasError() {
      return this.form.error
    },
    onSubmit() {
      this.onSubmitForm()
    },
    onCancel() {
      this.close()
    },
    onReset() {
      this.resetFormFields()
    },
    resetFormFields() {
      this.form = {
        name: '',
        api_key: '',
        password: '',
        shared_secret: '',
        is_marketing: false,
        schedule: null,
        template_schedule: null,
        error: true,
        ...this.initial,
      }
      this.schedule = !!this.form.schedule
      this.template_schedule = !!this.form.template_schedule
    },
    toggleSchedule(checked) {
      this.form.schedule = checked ? '' : null
    },
    toggleTemplateSchedule(checked) {
      this.form.template_schedule = checked ? '' : null
    },
    onSubmitForm(e) {
      e && e.preventDefault()
      if (!this.hasError()) {
        this.processing = true
        const {error, ...payload} = this.form
        this.$store.dispatch('shopify/stores/update', payload)
        .then(() => {
          this.$notify(`Store <${payload.name}> has been successfully updated.`)
          this.close()
        })
        .catch(e => {
          console.log(e)
          if (e.status == 404) {
            this.$notify({
              message: 'Store configuration is incorrect. Please try with another one.',
              type: 'danger',
            })
          } else {
            this.$notify({
              message: 'This store could not be updated... Try again later',
              type: 'danger',
            })
            this.close()
          }
        })
        .finally(() => {
          this.processing = false
        })
      }
    },
    formatForLowerCase(value, event) {
      return value.toLowerCase()
    },
  },
  created() {
    this.resetFormFields()
  },
}
</script>
