import Vue from "vue"
import Axios from 'axios'

import moment from 'moment'
Vue.prototype.moment = moment

Vue.filter('moment', function (value, format = '') {
  return moment(value).format(format)
})

import PaperDashboard from "./plugins/paperDashboard"
import "vue-notifyjs/themes/default.css"
Vue.use(PaperDashboard)

import App from "./App"
import router from "./router/index"
import store from './store'


import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.http.options.root = store.getters.apiHost
Vue.http.interceptors.push((request, next) => {
  next((response) => {
    if (response.status == 401) {
      store.dispatch('auth/logout')
    }
  });
})
const token = localStorage.getItem('token')
if (token) {
  Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.filter('formatNumber', function (value, decimal = 0) {
  if (!value) return ''
  let formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimal,
  });
  return formatter.format(value);
});

/* eslint-disable no-new */
(() => token ? store.dispatch('auth/getUser') : Promise.resolve())().finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
    created: () => {
    }
  }).$mount("#app")
})

String.prototype.toCamelCase = function() {
    const capitalize = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    let string = this.toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ').reduce((result, word) => result + capitalize(word.toLowerCase()))
    return string.charAt(0).toLowerCase() + string.slice(1)
}

String.prototype.validateEmail = function() {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(this.toLowerCase())
}
