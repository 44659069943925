<template>
  <b-card>
    <div class="mb-2">Organic Search Analytics</div>
    <div
      v-if="loading"
      class="d-flex justify-content-center "
    >  
      <b-spinner
        label="Spinning"
        variant="danger"
      ></b-spinner>
    </div>
    <div v-if="!loading && search_result">
      <table cellpadding="5" cellspacing="5" border="1" width="100%" class="data-table">
        <thead>
          <tr>
            <th rowspan="2">Source</th>
            <th rowspan="2">Customer</th>
            <th colspan="2">{{ search_result.startDate }} ~ {{ search_result.endDate }}</th>
          </tr>
          <tr>
            <th>Traffic</th>
            <th>Revenue</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowspan="2">Google</th>
            <th>B2B</th>
            <td>{{ search_result.result.google.B2B.traffic }}</td>
            <td>${{ search_result.result.google.B2B.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th>B2C</th>
            <td>{{ search_result.result.google.B2C.traffic }}</td>
            <td>${{ search_result.result.google.B2C.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th rowspan="2">Yahoo</th>
            <th>B2B</th>
            <td>{{ search_result.result.yahoo.B2B.traffic }}</td>
            <td>${{ search_result.result.yahoo.B2B.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th>B2C</th>
            <td>{{ search_result.result.yahoo.B2C.traffic }}</td>
            <td>${{ search_result.result.yahoo.B2C.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th rowspan="2">Bing</th>
            <th>B2B</th>
            <td>{{ search_result.result.bing.B2B.traffic }}</td>
            <td>${{ search_result.result.bing.B2B.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th>B2C</th>
            <td>{{ search_result.result.bing.B2C.traffic }}</td>
            <td>${{ search_result.result.bing.B2C.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th rowspan="2">Others</th>
            <th>B2B</th>
            <td>{{ search_result.result.others.B2B.traffic }}</td>
            <td>${{ search_result.result.others.B2B.revenue | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th>B2C</th>
            <td>{{ search_result.result.others.B2C.traffic }}</td>
            <td>${{ search_result.result.others.B2C.revenue | formatNumber(2) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="2">Total</th>
            <td>{{ search_result.total.traffic }}</td>
            <td>${{ search_result.total.revenue | formatNumber(2) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </b-card>
</template>

<script>
import Vue from "vue"
export default {
  data() {
    return {
      loading: false,
      search_result: null,
    }
  },
  methods: {
    getChangePercentage(first, second) {
      return ((first / second - 1) * 100).toFixed(2) + '%';
    },
    percentageValue(first, second) {
      return ((first / second) * 100).toFixed(2) + '%';
    },
  },
  async mounted() {
    this.loading = true

    this.search_result = await Vue.http.post('salesforce/reports/organic_search_data')
    .then(async (resp) => {
      if (resp.body.success) {
        return Promise.resolve(resp.body.data)
      }
      throw resp.body
    })
    .catch(err => Promise.reject(err))
    .finally(() => this.loading = false)
  },
};
</script>