<template>
  <div>
    <div v-show="!tryAgain">
      <b-row>
        <b-col class="text-center">
          <p-button class="mx-4" type="info" round size="sm" :disabled="processing" @click.native="onRequestDownload" :loading="processing">Download All Files</p-button>
          <p-button class="mx-4" type="warning" round size="sm" @click.native="onTryAgain">Create New</p-button>
        </b-col>
      </b-row>
      <hr/>
      <div
        class="images-preview-container mt-3"
        v-if="!!resultData"
      >
        
        <div class="image-previews">
          <div
            class="image-preview mr-4 mb-4"
            v-for="(file, index) in resultData.files"
            :key="index"
          >
            <img :src="file.location" />
          </div>
        </div>
      </div>
    </div>
    <b-form
      v-show="tryAgain"
      class="template-inputs-area"
      @submit="onSubmit"
      @reset="onReset"
    >
      <b-row class="form-actions">
        <b-col>
          <p-button type="success" round size="sm" class="pull-right" :disabled="hasError() || processing" @click.native="onSubmitForm" :loading="processing">Submit</p-button>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col>
          <b-form-group
            label="Background Images"
          >
            <b-input-group>
              <b-form-file
                id="bg-image-files"
                v-model="form.bgImageFiles"
                accept="image/*"
                :state="!!form.bgImageFiles.length"
                placeholder="Choose Background Images..."
                drop-placeholder="Drop files here..."
                multiple
                :file-name-formatter="formatNames"
              >
               <template slot="file-name" slot-scope="{ names }">
                 <b-badge variant="dark">{{ names[0] }}</b-badge>
                 <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                   + {{ names.length - 1 }} More files
                 </b-badge>
               </template>
              </b-form-file>
              <b-input-group-append v-if="form.bgImageFiles.length > 0">
                <b-button class="btn-clear-all" variant="danger" @click="clearAllBgImages()">Clear All</b-button>
              </b-input-group-append>
            </b-input-group>
            <div
              class="images-preview-container mt-3"
              v-if="form.bgImageFiles.length > 0"
            >
              
              <div class="image-previews">
                <div
                  class="image-preview mr-4 mb-4"
                  v-for="(file, index) in form.bgImageFiles"
                  :key="index"
                >
                  <img :src="bgImages[index]" />
                  <div class="btn-close" @click="removeBgImageFromList(index)">
                    <i class="fa fa-times" />
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Foreground Image"
          >
            <b-form-file
              id="fg-image-file"
              v-model="form.fgImageFile"
              accept="image/*"
              :state="!!form.fgImageFile"
              placeholder="Choose Foreground Image..."
              drop-placeholder="Drop file here..."
              :file-name-formatter="formatNames"
            >
             <template slot="file-name" slot-scope="{ names }">
               <b-badge variant="dark">{{ names[0] }}</b-badge>
             </template>
            </b-form-file>
            <div
              class="images-preview-container mt-4"
              v-if="!!form.fgImageFile"
            >
              <div class="image-previews">
                <div
                  class="image-preview fg-image-preview"
                >
                  <img :src="fgImage" />
                  <div class="btn-close" @click="removeFgImage()">
                    <i class="fa fa-times" />
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            label="H1"
          >
            <b-form-input v-model="form.textH1" :state="form.textH1.length > 0"></b-form-input>
          </b-form-group>
          <b-form-group
            label="H2"
          >
            <b-form-input v-model="form.textH2" :state="form.textH2.length > 0"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Line"
          >
            <b-form-input v-model="form.textLine" :state="form.textLine.length > 0"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Button"
          >
            <b-form-input v-model="form.textButton" :state="form.textButton.length > 0"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-loading :loading="processing" />
  </div>
</template>
<style lang="scss">
  .template-inputs-area {
    .btn-clear-all {
      line-height: 100%;
    }
    .form-actions {
      margin-bottom: 15px;
    }
  }
  .images-preview-container {
    .image-previews {
      display: flex;
      flex-wrap: wrap;
    }
    .image-preview {
      position: relative;
      border: dotted 1px #aaa;
      img {
        width: 200px;
        height: 150px;
        object-fit: cover;
      }
      .btn-close {
        width: 24px;
        height: 24px;
        border: solid 2px #aaa;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: -12px;
        right: -12px;
        z-index: 8;
      }
      &.fg-image-preview {
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 350px;
          object-fit: contain;
        }
      }
    }
  }
</style>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Notification } from 'vue-notifyjs';

export default {
  data() {
    return {
      loading: false,
      processing: false,
      tryAgain: true,
      resultData: null,
      form: {
        bgImageFiles: [],
        fgImageFile: null,
        textH1: '',
        textH2: '',
        textLine: '',
        textButton: '',
        error: true,
      },
    }
  },
  watch: {
    form: {
      handler() {
        this.validate()
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters([
      'apiHost',
    ]),
    ...mapGetters('auth', {
      'authToken': 'token',
    }),
    bgImages() {
      return this.form.bgImageFiles.map(file => URL.createObjectURL(file));
    },
    fgImage() {
      return this.form.fgImageFile && URL.createObjectURL(this.form.fgImageFile) || null;
    },
  },
  methods: {
    validate() {
      this.form.error = [
        this.form.bgImageFiles.length == 0,
        !this.form.fgImageFile,
        !this.form.textH1,
        !this.form.textH2,
        !this.form.textLine,
        !this.form.textButton,
      ].some(error => error)
    },
    hasError() {
      return this.form.error
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`
    },
    removeBgImageFromList(index) {
      this.form.bgImageFiles.splice(index, 1);
    },
    clearAllBgImages() {
      this.form.bgImageFiles = [];
    },
    removeFgImage() {
      this.form.fgImageFile = null;
    },
    onSubmit() {
      this.onSubmitForm()
    },
    onReset() {
      this.resetFormFields()
    },
    resetFormFields() {
      this.form = {
        bgImageFiles: [],
        fgImageFile: null,
        textH1: '',
        textH2: '',
        textLine: '',
        textButton: '',
      };
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (!this.hasError()) {
        this.processing = true;
        const {error, ...payload} = this.form;

        const formData = new FormData();
        formData.append('textH1', this.form.textH1);
        formData.append('textH2', this.form.textH2);
        formData.append('textLine', this.form.textLine);
        formData.append('textButton', this.form.textButton);
        formData.append('fgImage', this.form.fgImageFile);
        this.form.bgImageFiles.forEach((file, index) => formData.append('bgImages', file));

        Vue.http.post('admin/ads/process', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(resp => {
          Notification.notify({
            message: 'Images are processed successfully.',
          });
          this.resultData = resp.body.data;
          this.tryAgain = false;
          this.resetFormFields();
        })
        .catch(err => {
          Notification.notify({
            message: 'Images processing failed.',
            type: 'danger',
          });
        })
        .finally(() => {
          this.processing = false;
        });
      }
    },
    onRequestDownload() {
      window.open(`${this.apiHost}/admin/ads/download/${this.resultData.version_number}?token=${this.authToken}`, '_blank');
    },
    onTryAgain() {
      this.tryAgain = true;
    },
  },
};
</script>