<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel"/>
        <sidebar-link to="/shopify" name="Shopify" icon="ti-shopping-cart"/>
        <sidebar-link to="/shopify-audit-logs" name="Shopify Audit Logs" icon="ti-shopping-cart"/>
        <sidebar-link to="/reports" name="Reports" icon="ti-bookmark-alt" v-if="authUser.is_admin" />
        <sidebar-link to="/ads-generator" name="ADs Generator" icon="ti-sharethis" v-if="authUser.is_admin" />
        <sidebar-link to="/find-dead-links" name="Find Dead Links" icon="ti-link" v-if="authUser.is_admin" />
        <sidebar-link to="/spell-check-whitelist" name="Spell Check Whitelist" icon="ti-text" v-if="authUser.is_admin" />
      </template>
      <mobile-menu>
        <li class="nav-item">
          <a href="javascript:void(0)" @click="logout" class="nav-link">Log Out <span style="text-transform: initial;">({{authUser.name}})</span></a>
        </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import TopNavbar from "./TopNavbar.vue"
import DashboardContent from "./Content.vue"
import MobileMenu from "./MobileMenu"

export default {
  components: {
    TopNavbar,
    DashboardContent,
    MobileMenu,
  },
  computed: {
    ...mapGetters('auth', {
      'authUser': 'user',
    }),
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout() {
      this.$store.dispatch('auth/logout')
    }
  },
};
</script>
