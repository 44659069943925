<template>
  <b-modal
    class="deleteStoreModal"
    hide-header
    hide-footer
    centered
    ref="deleteStoreModal"
    @open="open"
  >
    <b-form @submit="onSubmit" @reset="onReset" class="p-4">
      <b-row>
        <b-col>
          <p>Do you really want to disconnect this store({{form.name}})? You cannot restore all backup data for this store once proceed.</p>
        </b-col>
      </b-row>
      <b-row class="form-actions">
        <b-col>
          <p-button type="success" round size="sm" class="pull-right" :disabled="processing" @click.native="onSubmitForm" :loading="processing">Yes</p-button>
          <p-button type="danger" round size="sm" class="pull-right mr-2" @click.native="onCancel">No</p-button>
        </b-col>
      </b-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      processing: false,
      initial: {
      },
      form: {
      }
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  methods: {
    open(store) {
      this.initial = store
      this.resetFormFields()
      this.$refs.deleteStoreModal.show()
    },
    close() {
      this.$refs.deleteStoreModal.hide()
    },
    onSubmit() {
      this.onSubmitForm()
    },
    onCancel() {
      this.close()
    },
    onReset() {
      this.resetFormFields()
    },
    resetFormFields() {
      this.form = {
        ...this.initial
      }
      this.processing = false
    },
    onSubmitForm(e) {
      e && e.preventDefault()
      this.processing = true
      const {error, ...payload} = this.form
      this.$store.dispatch('shopify/stores/remove', payload)
      .then(() => this.$router.push('/shopify'))
      .finally(() => {
        this.processing = false
        this.close()
      })
    }
  },
  created() {
    this.resetFormFields()
  },
}
</script>
