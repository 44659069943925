<template>
  <div>
    <b-card>
      <template slot="header">
        <b-card-title>
          <strong>{{title}}</strong>
        </b-card-title>
        <p class="card-category mt-2">{{subTitle}}</p>
      </template>
      <template slot="default">
        <b-jumbotron>
          <template v-slot:header>Ecofibre Dashboard</template>

          <template v-slot:lead>
            This is where you are gonna handle every backend actions for Ecofibre.
          </template>

          <hr class="my-4">
          <p>
            v1.0.0
          </p>
        </b-jumbotron>
      </template>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Intro",
      subTitle: "Ecofibre Admin Dashboard",
    }
  },
}
</script>
<style>
</style>
