import Vue from 'vue'

export default {
  add({commit}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/stores/add', payload)
      .then(resp => {
        const {store} = resp.data
        commit('add', {store})
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
  remove({commit, rootGetters, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/stores/remove', payload)
      .then(async (resp) => {
        commit('remove', payload)
        if (payload.id == rootGetters['shopify/stores/store'].id) {
          // commit('shopify/setActiveStore', null, {root: true})
          await dispatch('setActiveByName', '')
        }
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
  update({commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/stores/update', payload)
      .then(async (resp) => {
        const {store} = resp.data
        commit('update', store)
        await dispatch('setActiveByName', store.name)
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
  load({commit, state}, force = false) {
    return new Promise((resolve, reject) => {
      if (state.loaded && !force) return resolve()
      commit('loading', true)
      Vue.http.post('admin/shopify/stores/list', {})
      .then(resp => {
        const {stores} = resp.data
        commit('load', {stores})
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
  setActiveByName({getters, commit, dispatch}, name) {
    return new Promise(async (resolve, reject) => {
      commit('setActiveStore', getters.getByName(name))
      try {
        if (name) {
          await dispatch('getBackups', name)
        }
        resolve()
      } catch(e) {
        reject(e)
      }
    });
  },
  getBackups({getters, commit}, name) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/backup/list', {
        name,
      })
      .then((resp) => Promise.resolve(resp.body))
      .then(({success, data}) => {
        if (!success) throw 'Failed to get backups';
        commit('setBackups', data.backups)
        resolve()
      })
      .catch(err => {
        commit('setBackups', [])
        reject(err)
      })
      .finally(() => commit('loading', false))
    })
  },
  backupInit({getters, commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/backup/init', payload)
      .then(async (resp) => {
        await dispatch('getBackups', payload.name)
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
  backupRestore({getters, commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      commit('setTargetStore', payload.store)
      Vue.http.post('admin/shopify/backup/restore', payload)
      .then(async () => {
        await dispatch('getBackups', getters.store.name)
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => {
        commit('loading', false)
        // commit('setTargetStore', '')
      })
    })
  },
  backupRestoreCompleted({getters, commit, dispatch}, payload) {
    commit('setTargetStore', '')
  },
  backupRemove({getters, commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/backup/remove', payload)
      .then(async () => {
        await dispatch('getBackups', getters.store.name)
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
  backupActivate({getters, commit, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit('loading', true)
      Vue.http.post('admin/shopify/backup/activate', payload)
      .then(async () => {
        await dispatch('getBackups', getters.store.name)
        resolve()
      })
      .catch(err => reject(err))
      .finally(() => commit('loading', false))
    })
  },
}
