export default {
  stores: state => state.list || [],
  loading: state => state.loading || false,
  loaded: state => state.loaded || state.list.length > 0,
  getByName: state => name => state.list.find(store => store.name == name),
  store: state => state.activeStore || null,
  backups: state => state.backups || [],
  running: state => state.running || false,
  targetStore: state => state.targetStore || (state.activeStore && state.activeStore.name || ''),
}
