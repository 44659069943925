import Vue from 'vue'

import modules from './modules'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const initialState = () => ({
  list: [],
  loading: false,
  loaded: false,
  backups: [],
  running: false,
  activeStore: null,
  targetStore: '',
})

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
  modules,
}
