<template>
  <div class="xml-wrapper">
    <div class="xml-toolbar">
      <b-button class="mr-2" variant="outline-primary" @click="loadProducts()">Load Products</b-button>
      <b-button class="mr-2" variant="outline-primary" v-if="products.length > 0" @click="exportXML()">Export XML</b-button>
      <b-button class="mr-2" variant="outline" v-if="products.length > 0" @click="expandAll()">Expand All</b-button>
      <b-button class="mr-2" variant="outline" v-if="products.length > 0" @click="collapseAll()">Collapse All</b-button>
      <b-button class="mr-2" variant="outline-warning" v-if="products.length > 0" @click="resetProducts()">Reset</b-button>
      <b-button class="mr-2" variant="outline-warning" v-if="products.length > 0" @click="saveProducts()">Save</b-button>
    </div>
    <div class="my-4 xml-content">
      <div class="xml-products">
        <div class="xml-product mb-2" v-for="product in products" :key="product.id">
          
          <b-form-checkbox v-model="product.active">
            <b-button
              class="text-left"
              block
              size="sm"
              @click="product.visible = !product.visible && product.active"
            >
              <i class="ti-minus" v-if="product.visible"></i>
              <i class="ti-plus" v-else></i>
              <span class="ml-2" style="text-transform: none;">{{ product.title }}</span>
              <span class="text-warning ml-2 float-right" v-if="product.variants.length > 1" style="text-transform: none;">Multiple Prices</span>
            </b-button>
          </b-form-checkbox>
          <b-collapse v-model="product.visible" class="mt-2">
            <b-card>
              <div class="product-variants" v-if="product.variants.length > 1">
                <div
                  v-for="variant in product.variants"
                  :key="variant.id"
                >
                  <b-badge>{{ variant.title }}</b-badge> {{ variant.price}}
                </div>
              </div>
              <b-form-group
                label="ID"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.id"
                  :state="!!product.id"
                  placeholder="ID"
                />
              </b-form-group>
              <b-form-group
                label="Title"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.title"
                  :state="!!product.title && product.title.length > 0"
                  placeholder="Title"
                />
              </b-form-group>
              <b-form-group
                label="Google_product_category"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.google_product_category"
                  :state="!!product.google_product_category && product.google_product_category.length > 0"
                  placeholder="Google_product_category"
                />
              </b-form-group>
              <b-form-group
                label="Price"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.price"
                  :state="!!product.price && product.price.length > 0"
                  placeholder="0.00 USD"
                />
              </b-form-group>
              <b-form-group
                label="Link"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.link"
                  :state="!!product.link && product.link.length > 0"
                  placeholder="https://"
                />
              </b-form-group>
              <b-form-group
                label="Image link"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.image_link"
                  :state="!!product.image_link && product.image_link.length > 0"
                  placeholder="https://"
                />
              </b-form-group>
              <b-form-group
                label="Description"
                label-cols="2"
                label-align="right"
              >
                <b-form-textarea
                  v-model="product.description"
                  :state="!!product.description && product.description.length > 0"
                  placeholder=""
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                label="Gender"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.gender"
                  :state="!!product.gender && product.gender.length > 0"
                  placeholder="Gender"
                />
              </b-form-group>
              <b-form-group
                label="Availability"
                label-cols="2"
                label-align="right"
              >
                <b-form-input
                  v-model="product.availability"
                  :state="!!product.availability && product.availability.length > 0"
                  placeholder="In Stock | Out of Stock"
                />
              </b-form-group>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
    <b-loading :loading="loading || productsLoading" />
  </div>
</template>
<script>
import Vue from "vue"
import { mapGetters } from 'vuex'
export default {
  props: ['targetStore'],
  data() {
    return {
      productsLoading: false,
      products: [],
    }
  },
  computed: {
    ...mapGetters('auth', {
      'authUser': 'user',
    }),
    ...mapGetters('shopify/stores', [
      'store',
      'loading',
    ]),
    storeName: function() {
      return this.targetStore || this.store.name
    },
  },
  methods: {
    async loadProducts() {
      this.productsLoading = true

      const products = await Vue.http.post('admin/shopify/store/getProducts', {
        name: this.storeName,
      })
      .then(async (resp) => {
        if (resp.body.success) {
          return Promise.resolve(resp.body.data.products)
        }
        throw resp.body
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.productsLoading = false)

      this.products = products.map(product => ({
        ...product,
        visible: false,
        active: true,
      }))
    },
    expandAll() {
      this.products.forEach(product => {
        product.visible = product.active
      })
    },
    collapseAll() {
      this.products.forEach(product => {
        product.visible = false
      })
    },
    async exportXML() {
      this.productsLoading = true

      const productsXML = await Vue.http.post('admin/shopify/store/generateXML', {
        name: this.storeName,
        products: this.products.filter(product => product.active),
      })
      .then(async (resp) => {
        if (resp.body.success) {
          return Promise.resolve(resp.body.data)
        }
        throw resp.body
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.productsLoading = false)

      var filename = this.storeName + '.xml';
      var pom = document.createElement('a');
      var bb = new Blob([productsXML], {type: 'text/plain'});

      pom.setAttribute('href', window.URL.createObjectURL(bb));
      pom.setAttribute('download', filename);

      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
      pom.draggable = true; 
      pom.classList.add('dragout');

      pom.click();
    },
    async resetProducts() {
      this.productsLoading = true
      await Vue.http.post('admin/shopify/store/resetProducts', {
        name: this.storeName,
      });
      this.$notify({
        message: 'Products are reset. Now loading fresh sets ...',
        type: 'info',
      })
      await this.loadProducts()
    },
    async saveProducts() {
      this.productsLoading = true
      await Vue.http.post('admin/shopify/store/saveProducts', {
        name: this.storeName,
        products: this.products.map(product => ({
          ...product,
          active: undefined,
          visible: undefined,
        })),
      })
      .finally(() => this.productsLoading = false)
      this.$notify({
        message: 'Products are saved.',
        type: 'info',
      })
    }
  }
}
</script>
<style lang="scss">
  .xml-wrapper {

  }
</style>