<template>
  <b-card>
    <div
      v-if="loading"
      class="d-flex justify-content-center "
    >  
      <b-spinner
        label="Spinning"
        variant="danger"
      ></b-spinner>
    </div>
    <div v-if="!loading && monthly_revenue_data">
      <table cellpadding="5" cellspacing="5" border="1" width="100%">
        <thead>
          <tr>
            <th></th>
            <th>{{ moment(monthly_revenue_data.lastMonthEndDate).format('YYYY-MM') }}</th>
            <th>{{ moment(monthly_revenue_data.prevMonthEndDate).format('YYYY-MM') }}</th>
            <th>Change</th>
            <th>YTD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th align="left">B2C site visits</th>
            <td>{{ monthly_revenue_data.GA_lastMonthData | formatNumber }}</td>
            <td>{{ monthly_revenue_data.GA_prevMonthData | formatNumber }}</td>
            <td>{{ getChangePercentage(monthly_revenue_data.GA_lastMonthData, monthly_revenue_data.GA_prevMonthData) }}</td>
            <td>{{ monthly_revenue_data.GA_yearData | formatNumber }}</td>
          </tr>
          <tr>
            <th align="left">% customers purchase</th>
            <td>{{ percentageValue(monthly_revenue_data.SF_lastMonthData['order_count'], monthly_revenue_data.GA_lastMonthData) }}</td>
            <td>{{ percentageValue(monthly_revenue_data.SF_prevMonthData['order_count'], monthly_revenue_data.GA_prevMonthData) }}</td>
            <td>{{ getChangePercentage(monthly_revenue_data.SF_lastMonthData['order_count'] / monthly_revenue_data.GA_lastMonthData, monthly_revenue_data.SF_prevMonthData['order_count'] / monthly_revenue_data.GA_prevMonthData) }}</td>
            <td>{{ percentageValue(monthly_revenue_data.SF_yearData['order_count'], monthly_revenue_data.GA_yearData) }}</td>
          </tr>
          <tr>
            <th align="left">Average $ purchase</th>
            <td>${{ (monthly_revenue_data.SF_lastMonthData['order_total'] / monthly_revenue_data.SF_lastMonthData['order_count']) | formatNumber(2) }}</td>
            <td>${{ (monthly_revenue_data.SF_prevMonthData['order_total'] / monthly_revenue_data.SF_prevMonthData['order_count']) | formatNumber(2) }}</td>
            <td>${{ getChangePercentage(monthly_revenue_data.SF_lastMonthData['order_total'] / monthly_revenue_data.SF_lastMonthData['order_count'], monthly_revenue_data.SF_prevMonthData['order_total'] / monthly_revenue_data.SF_prevMonthData['order_count']) }}</td>
            <td>${{ (monthly_revenue_data.SF_yearData['order_total'] / monthly_revenue_data.SF_yearData['order_count']) | formatNumber(2) }}</td>
          </tr>
          <tr>
            <th align="left">B2C revenue</th>
            <td>${{ Math.round(monthly_revenue_data.SF_lastMonthData['order_total']) | formatNumber }}</td>
            <td>${{ Math.round(monthly_revenue_data.SF_prevMonthData['order_total']) | formatNumber }}</td>
            <td>${{ getChangePercentage(monthly_revenue_data.SF_lastMonthData['order_total'], monthly_revenue_data.SF_prevMonthData['order_total']) }}</td>
            <td>${{ Math.round(monthly_revenue_data.SF_yearData['order_total']) | formatNumber }}</td>
          </tr>
          <tr>
            <th align="left">Number of B2C orders</th>
            <td>{{ monthly_revenue_data.SF_lastMonthData['order_count'] | formatNumber }}</td>
            <td>{{ monthly_revenue_data.SF_prevMonthData['order_count'] | formatNumber }}</td>
            <td>{{ getChangePercentage(monthly_revenue_data.SF_lastMonthData['order_count'], monthly_revenue_data.SF_prevMonthData['order_count']) }}</td>
            <td>{{ monthly_revenue_data.SF_yearData['order_count'] | formatNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card>
</template>

<script>
import Vue from "vue"
export default {
  data() {
    return {
    }
  },
  props: [
  	'loading',
  	'monthly_revenue_data',
  ],
  methods: {
    getChangePercentage(first, second) {
      return ((first / second - 1) * 100).toFixed(2) + '%';
    },
    percentageValue(first, second) {
      return ((first / second) * 100).toFixed(2) + '%';
    },
  },
};
</script>