<template>
  <b-card>
    <div class="mb-2">Upsell Analytics - {{ moment(currentDate).format('YYYY-MM-DD') }} <a class="historyLink text-primary pull-right" @click="() => showHistory()">History</a></div>
    <div
      v-if="loading"
      class="d-flex justify-content-center"
    >  
      <b-spinner
        label="Spinning"
        variant="danger"
      ></b-spinner>
    </div>
    <template
      v-else
    >
      <div v-if="showDates && analyticsDataSnapshots">
        <div
          v-for="snapshot in analyticsDataSnapshots"
        >
          <a class="text-primary historyLink" @click="() => showAnalytics(snapshot)">{{ moment(snapshot.date).format('YYYY-MM-DD') }}</a>
        </div>
      </div>
      <div v-if="!showDates && analyticsData">
        <table cellpadding="5" cellspacing="5" border="1" width="100%">
          <tbody>
            <tr>
              <td>Total number of upsell orders in last 30 days</td>
              <td align="right">{{ analyticsData['count_upsell'] }}</td>
            </tr>
            <tr>
              <td>Total number of non-upsell orders in last 30 days</td>
              <td align="right">{{ analyticsData['count_non_upsell'] }}</td>
            </tr>
            <tr>
              <td>% of all orders that have an upsell</td>
              <td align="right">{{ analyticsData['upsell_percentage'] | formatNumber(2) }}%</td>
            </tr>
            <tr>
              <td>Total $ revenue of upsell orders in last 30 days</td>
              <td align="right">${{ analyticsData['revenue_upsell'] | formatNumber(2) }}</td>
            </tr>
            <tr>
              <td>Total $ revenue of non-upsell orders in last 30 days</td>
              <td align="right">${{ analyticsData['revenue_non_upsell'] | formatNumber(2) }}</td>
            </tr>
            <tr>
              <td>Avg $ order for upsell orders</td>
              <td align="right">${{ analyticsData['avg_upsell'] | formatNumber(2) }}</td>
            </tr>
            <tr>
              <td>Avg $ order for non-upsell orders</td>
              <td align="right">${{ analyticsData['avg_non_upsell'] | formatNumber(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </b-card>
</template>

<script>
import Vue from "vue"
export default {
  data() {
    return {
      loading: false,
      analyticsDataSnapshots: null,
      analyticsData: null,
      showDates: false,
      currentDate: new Date(),
    }
  },
  methods: {
    async getAnalyticsSnapshots() {
      this.loading = true
      this.analyticsDataSnapshots = await Vue.http.post('salesforce/reports/get_shopify_upsell_data_snapshots')
      .then(async (resp) => {
        if (resp.body.success) {
          return Promise.resolve(resp.body.data)
        }
        throw resp.body
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false)
    },
    showHistory() {
      this.showDates = true
      if (!this.analyticsDataSnapshots) {
        this.getAnalyticsSnapshots()
      }
    },
    showAnalytics(snapshot) {
      this.analyticsData = snapshot.data;
      this.showDates = false;
      this.currentDate = snapshot.date;
    },
  },
  async mounted() {
    this.loading = true

    this.analyticsData = await Vue.http.post('salesforce/reports/shopify_upsell_data')
    .then(async (resp) => {
      if (resp.body.success) {
        return Promise.resolve(resp.body.data)
      }
      throw resp.body
    })
    .catch(err => Promise.reject(err))
    .finally(() => this.loading = false)
  },
};
</script>

<style type="text/css">
  .historyLink {
    cursor: pointer;
  }
</style>